import React from "react";
//import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down("md")]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1),
  },
  image: {
    perspectiveOrigin: "left center",
    transformStyle: "preserve-3d",
    perspective: 1500,
    "& > img": {
      maxWidth: "90%",
      height: "auto",
      transform: "rotateY(-10deg) rotateX(10deg)",
      backfaceVisibility: "hidden",
      boxShadow: theme.shadows[16],
    },
  },
  shape: {
    position: "absolute",
    top: 0,
    left: 0,
    "& > img": {
      maxWidth: "90%",
      height: "auto",
    },
  },
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h1" color="secondary">
                <b>Low-Code Backend for Games</b>
                <br></br>
              </Typography>
              <Typography variant="h4" color="textPrimary">
                Simple Unity plugin. For Developers by Developers.
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  You can focus on developing your game(s) and we will take care
                  of scaling your backend. With our state-of-the-art game
                  backend, today it gives creators across industries the freedom
                  and control to deliver games.
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  // component={RouterLink}
                  // to="/register"
                  // component={RouterLink}
                  href="/login"
                >
                  START NOW FREE!
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div>
                <picture className={classes.image}>
                  {/* <source srcset="/static/home/Hero.webp" type="image/webp" /> */}
                  <img src="/static/home/Hero.png" alt="Hero" />
                </picture>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
