import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout'
import AuthGuard from 'src/components/AuthGuard'
import HomeView from 'src/views/home/HomeView';
import GuestGuard from 'src/components/GuestGuard'

import MainLayout from 'src/layouts/MainLayout'
import LoadingScreen from 'src/components/LoadingScreen'


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  // {
  //   exact: true,
  //   path: '/',
  //   //component: LanguageView
  //   //component: lazy(() => import('src/views/auth/LoginView'))
  //   //component: () => <Redirect to="/app/reports/dashboard" />
  //   component: () => <Redirect to="/app/management/map" />
  // },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
  /*    {
        exact: true,
        path: '/app/management/players',
        component: lazy(() => import('src/views/players/PlayersListView'))
      },
      {
        exact: true,
        path: '/app/management/players/create',
        component: lazy(() => import('src/views/players/PlayersModify'))
      },
      {
        exact: true,
        path: '/app/management/players/:playerID',
        component: lazy(() => import('src/views/players/PlayersModify'))
      },
  */
      {
        exact: true,
        path: '/app/management/applications',
        component: lazy(() => import('src/views/applications/ApplicationsListView'))
      },
      {
        exact: true,
        path: '/app/management/applications/create',
        component: lazy(() => import('src/views/applications/ApplicationsModify'))
      },
      {
        exact: true,
        path: '/app/management/applications/:applicationID',
        component: lazy(() => import('src/views/applications/ApplicationsModify'))
      },
      {
        exact: true,
        path: '/app/management/leaderboards',
        component: lazy(() => import('src/views/leaderboards/LeaderboardsListView'))
      },
      {
        exact: true,
        path: '/app/management/leaderboards/create',
        component: lazy(() => import('src/views/leaderboards/LeaderboardsModify'))
      },
      {
        exact: true,
        path: '/app/management/leaderboards/:leaderboardID/application/:applicationID',
        component: lazy(() => import('src/views/leaderboards/LeaderboardsModify'))
      },
      {
        exact: true,
        path: '/app/account',
        component: () => <Redirect to="/app/account/general" />
      },
      {
        exact: true,
        path: '/app/saves',
        component: lazy(() => import('src/views/saves'))
      },
      {
        exact: true,
        path: '/app/configs',
        component: lazy(() => import('src/views/configs'))
      },
      {
        exact: true,
        path: '/app/nfts',
        component: lazy(() => import('src/views/nfts'))
      },
      {
        exact: true,
        path: '/app/subscription',
        component: lazy(() => import('src/views/subscription'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
{
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
