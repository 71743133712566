import React from 'react';

import Page from 'src/components/Page';
import BottomBar from 'src/layouts/MainLayout/BottomBar';

import { makeStyles } from '@material-ui/core';

import Affiliate from './Affiliate';
//import Banner from './Banner';
import Contact from './Contact';
import FAQS from './FAQS';
import FeatureBreakdown from './FeatureBreakdown';
import Features from './Features';
import Hero from './Hero';
import Pricing from './Pricing';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Hero />
      <Features />
      <FeatureBreakdown />
      <Pricing />
      <FAQS />
      <Affiliate />
      <Contact />
      <BottomBar />
    </Page>
  );
};

export default HomeView;
